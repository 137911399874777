import React from 'react';
import WebTab from '../_code-web';
import ReactNativeTab from '../_code-reactnative';
import { PageHero, PageWithSubNav, PlatformTabs } from '../../../../components';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="code"
      title="Group Avatar"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Avatars" tierThree="Group" />

      <PlatformTabs
        platforms={{
          web: <WebTab avatarType="group" />,
          reactnative: <ReactNativeTab avatarType="group" />,
        }}
      />
    </PageWithSubNav>
  );
};

export default IndexPage;
